export function isBrowserUpdateNeeded() {
    const browsers = [
        { name: 'Mozilla Firefox', str: 'Firefox', v: 58 },
        { name: 'Samsung Internet', str: 'SamsungBrowser', v: 8 },
        { name: 'Opera', str: 'OPR', v: 50 },
        { name: 'old versions of Opera', str: 'Opera', v: 0 },
        { name: 'Microsoft Internet Explorer', str: 'Trident', v: 0 },
        { name: 'Microsoft Edge', str: 'Edge', v: 18 },
        { name: 'Google Chrome or Chromium', str: 'Chrome', v: 64 },
        { name: 'Safari', str: 'Safari', v: 12 },
    ];
    const userAgent = navigator.userAgent;
    let browserOffset;
    let browserVersion;

    for (let browser of browsers) {
        browserOffset = userAgent.indexOf(browser.str);

        if (browserOffset > -1) {
            if (browser.v === 0) return true;

            if (browser.name === 'Safari') {
                browserOffset = userAgent.indexOf('Version');
                browserVersion = userAgent
                    .substring(browserOffset + 8)
                    .split('.')[0];
            } else {
                browserVersion = userAgent
                    .substring(browserOffset + browser.str.length + 1)
                    .split('.')[0];
            }

            return parseInt(browserVersion) < browser.v;
        }
    }

    return false;
}
